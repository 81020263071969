import React from 'react'
import styles from './sharing_container.module.css'
import { ShareButtons } from 'shared'

export const SharingContainer = ({ shareUrl }: { shareUrl: string }): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className="container">
        <h2 className={styles.headline}>{i18n.t('projects.show.sharing.headline')}</h2>
        <ShareButtons
          platforms={['facebook', 'twitter', 'email', 'whatsapp', 'messenger']}
          mobileOnly={['whatsapp', 'messenger']}
          shape="round"
          shareUrl={shareUrl}
          className={styles.shareButtons}
          utmMedium="ppp_sharing_module"
        />
      </div>
    </div>
  )
}
