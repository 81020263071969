import React, { useCallback, useState } from 'react'
import styles from './matching_event_notice.module.css'
import wirWunderLogo from '@betterplace/assets/images/logos/wirwunder_logo.svg'
import { LogoTextBannerProps, MatchingEvent } from './types'
import { Modal, TrustedHtml } from 'shared'
import { ModalContent } from './modal'

export const LogoTextBanner = ({ backgroundColor, color, logoUrl, logoAlt, children }: LogoTextBannerProps) => {
  return (
    <div className={`${styles.logoTextBanner}`} style={{ backgroundColor, color }} data-testid="logo-text-banner">
      <img src={logoUrl} alt={logoAlt || ''} className={styles.logo} />
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export const MatchingEventNotice = ({
  matchingEvent,
  donateButton,
}: {
  matchingEvent: MatchingEvent
  donateButton?: React.ReactNode
}) => {
  const [show, setShow] = useState(false)
  const onHide = useCallback(() => setShow(false), [])

  const handleClick = useCallback(() => {
    setShow(true)
  }, [])

  if (!matchingEvent?.providedAmountInCents) return null

  const modalContent = <ModalContent matchingEvent={matchingEvent} onHide={onHide} donateButton={donateButton} />

  return (
    <>
      <LogoTextBanner
        backgroundColor={matchingEvent.backgroundColor}
        color={matchingEvent.color}
        logoUrl={matchingEvent.logo || wirWunderLogo}
      >
        <TrustedHtml as="span" className="mr-2">
          {matchingEvent.bannerContent}
        </TrustedHtml>
        <button onClick={handleClick} className="gentle-link">
          {i18n.t('projects.show.stats.matching_event.banner_read_conditions')}
        </button>
      </LogoTextBanner>
      <Modal
        id="matching-event"
        show={show}
        onHide={onHide}
        content={modalContent}
        header={matchingEvent.modalHeadline}
      />
    </>
  )
}
