import React from 'react'
import styles from './organisation_project_summary.module.css'
import { OrganisationInfo } from 'shared/components/organisation_info'
import { OrganisationProjectSummaryProps } from './types'

export const OrganisationProjectSummary = ({
  carrierProfilePictureUrl,
  carrierName,
  projectCity,
  projectCountry,
  shortDescription,
  carrierUrl,
}: OrganisationProjectSummaryProps) => {
  return (
    <div className={styles.container}>
      {carrierName && (
        <div className={styles.organisationInfo}>
          <OrganisationInfo
            carrierProfilePictureUrl={carrierProfilePictureUrl}
            carrierName={carrierName}
            city={projectCity}
            country={projectCountry}
            pictureWidth={80}
            carrierUrl={carrierUrl}
          />
        </div>
      )}
      <div className={styles.shortDescription}>
        {shortDescription || i18n.t('projects.show.organisation_project_summary.short_description_placeholder')}
      </div>
    </div>
  )
}
