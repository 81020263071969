import React from 'react'
import styles from './client_banner_list.module.css'
import { BannerList } from './types'

export const ClientBannerList = ({ clientBannerList }: { clientBannerList: BannerList }) => {
  if (clientBannerList.length === 0) return null
  return (
    <>
      <h2 className={styles.headline}>{i18n.t('projects.show.repo_teaser.headline')}</h2>
      <ul className={styles.container}>
        {clientBannerList.map((clientBanner) => (
          <li key={clientBanner.name}>
            <a href={clientBanner.url}>
              <img src={clientBanner.imageUrl} alt={clientBanner.name} />
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}
