import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import styles from './subscribe_modal.module.css'
import { I18nHtml, JSONFetcher, Modal, handleError } from 'shared'
import { SubscribeModalProps, SubscriptionFormProps } from './types'

export const SubscribeModal = (props: SubscribeModalProps): JSX.Element => (
  <Modal
    show={props.show}
    onHide={props.onHide}
    id="project-newsletter-subscription-modal"
    content={<SubscriptionForm {...props} />}
    header={i18n.t('projects.show.engagement.subscribe_modal_headline')}
    headerSubline={i18n.t('projects.show.engagement.subscribe_modal_subline')}
  />
)

export const SubscriptionForm = (props: SubscriptionFormProps): JSX.Element => {
  const [state, setState] = useState({
    email: '',
    projectNewsletter: true,
    platformNewsletter: true,
    submitted: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: undefined as any,
    showModal: false,
  })

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      JSONFetcher.post({
        url: props.url,
        body: {
          email: state.email,
          platform_newsletter: state.platformNewsletter,
          project_newsletter: state.projectNewsletter,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        success: (response: { error?: any }) => {
          if (response.error) {
            setState((old) => ({ ...old, error: response.error }))
          } else {
            setState((old) => ({ ...old, submitted: true, error: undefined }))
          }
        },
        onError: (error) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          handleError(error, error.response?.data?.error)
        },
      })
    },
    [props.url, state.email, state.platformNewsletter, state.projectNewsletter]
  )

  const onEmailChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setState((old) => (old.email !== event.target.value ? { ...old, email: event.target.value } : old)),
    []
  )

  const onProjectNewsletterChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setState((old) => ({ ...old, projectNewsletter: event.target.checked })),
    []
  )

  const onPlatformNewsletterChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setState((old) => ({ ...old, platformNewsletter: event.target.checked })),
    []
  )

  if (state.submitted) {
    return (
      <div>
        <p>{i18n.t('projects.show.engagement.subscribe_modal_thank_you')}</p>
        <button className="btn btn-primary btn-large" onClick={props.onHide}>
          {i18n.t('projects.show.engagement.subscribe_modal_close_button')}
        </button>
      </div>
    )
  }

  return (
    <div data-testid="subscription-form">
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="mailing_newsletter_opt_in_email">
            <span>E-Mail</span>
            <abbr>
              <span aria-hidden="true">&nbsp;*</span>
              <span className="sr-only">{`(${i18n.t('misc.required_field')})`}</span>
            </abbr>
          </label>
          <input
            className={classNames({ 'field-with-error': state.error })}
            id="mailing_newsletter_opt_in_email"
            type="text"
            value={state.email}
            onChange={onEmailChanged}
          />
          {state.error && <div className="help-inline">{state.error}</div>}
        </div>

        <div className={styles.formGroup}>
          <label>
            <input type="checkbox" checked={state.projectNewsletter} onChange={onProjectNewsletterChanged} />{' '}
            <I18nHtml
              i18nKey="projects.show.engagement.subscription_project_label"
              options={{
                project_name: props.projectTitle,
              }}
            />
          </label>
        </div>

        <div className={styles.formGroup}>
          <label>
            <input type="checkbox" checked={state.platformNewsletter} onChange={onPlatformNewsletterChanged} />{' '}
            <span>{i18n.t('projects.show.engagement.subscription_platform_label')}</span>
          </label>
        </div>

        <div className={styles.formActions}>
          <input
            type="submit"
            value={i18n.t('projects.show.engagement.subscribe_modal_button')}
            className="btn btn-primary btn-large"
          />
        </div>

        <I18nHtml
          className={styles.disclaimer}
          as="div"
          i18nKey="projects.show.engagement.subscribe_modal_disclaimer"
        />
      </form>
    </div>
  )
}
