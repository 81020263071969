import React, { useCallback, useState } from 'react'
import styles from './create_fundraising_event_cta.module.css'
import teaserImage from './create_fundraising_event_modal.svg'
import { CreateFundraisingEventCtaProps } from './types'
import { Modal } from 'shared'

export const CreateFundraisingEventCta = (props: CreateFundraisingEventCtaProps) => {
  const [showModal, setShowModal] = useState(false)

  const handleClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onHide = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <>
      <p>{i18n.t('projects.show.engagement.create_fundraising_event_introduction')}</p>
      <button className="simulated-link" onClick={handleClick}>
        {i18n.t('misc.learn_more_about_fundraising_events')}
      </button>

      <Modal
        id="create-fundraising-event"
        show={showModal}
        onHide={onHide}
        content={<ModalContent {...props} />}
        header={i18n.t('projects.show.engagement.create_fundraising_event_modal_headline')}
        size="modal-lg"
      />
    </>
  )
}

const ModalContent = ({ readmoreUrl, ctaUrl }: CreateFundraisingEventCtaProps): JSX.Element => (
  <div className={styles.modalContent}>
    <img src={teaserImage} width="100%" alt="" />
    <div>{i18n.t('projects.show.engagement.create_fundraising_event_modal_explanation')}</div>

    <div className={styles.ctaContainer}>
      <a className="btn btn-primary btn-large" href={ctaUrl} target="_blank" rel="noreferrer">
        {i18n.t('projects.show.engagement.create_fundraising_event_modal_cta')}
      </a>
      <a href={readmoreUrl} target="_blank" rel="noreferrer">
        {i18n.t('projects.show.engagement.create_fundraising_event_modal_readmore')}
      </a>
    </div>
  </div>
)
