import React from 'react'
import classNames from 'classnames'
import styles from './news_teaser_list.module.css'
import { A11y, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { BlogPostsApiResult } from './types'
import { NewsTeaser, themeBreakpoint, useJsonApi } from 'shared'
import { Swiper, SwiperSlide } from 'swiper/react'

export const NewsTeaserList = ({
  blogsApiUrl,
  newsListUrl,
}: {
  blogsApiUrl: string
  newsListUrl: string
}): JSX.Element | null => {
  const breakpoint = themeBreakpoint()
  const { data: blogList } = useJsonApi<BlogPostsApiResult>(blogsApiUrl)
  if (!blogList || blogList.total_entries === 0) return null

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.headline}>{i18n.t('projects.show.news_teaser_list.headline')}</h2>
      </div>
      {blogList.total_entries === 1 ? (
        <div className={styles.singleNewsContainer} data-testid="news-teaser-list">
          {blogList.data.map((blog, index) => {
            return <NewsTeaser key={index} news={blog} />
          })}
        </div>
      ) : (
        <div className={classNames('generic-teaser-swiper', styles.swiperContainer)} data-testid="news-teaser-list">
          <Swiper
            modules={[A11y, Mousewheel, Navigation, Pagination]}
            slidesPerView={1}
            spaceBetween={16}
            pagination={{
              clickable: blogList.total_entries > 1,
            }}
            watchSlidesProgress={true}
            roundLengths
            mousewheel={{ forceToAxis: true }}
            wrapperTag="ul"
            a11y={{
              slideRole: 'listitem',
            }}
            breakpoints={{
              [breakpoint]: {
                slidesPerView: 3,
              },
            }}
          >
            {blogList.data.map((blog) => {
              return (
                <SwiperSlide tag="li" key={blog.id} className={styles.slide}>
                  <NewsTeaser news={blog} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}
      {blogList.total_entries > 3 && (
        <div className={styles.showAllLink}>
          <a href={newsListUrl}>
            {i18n.t('projects.show.news_teaser_list.show_all_blogs_link_html', { count: blogList.total_entries })}
          </a>
        </div>
      )}
    </>
  )
}
