import React from 'react'
import SparkassenLogo from '@betterplace/assets/images/logos/wirwunder_sparkasse.svg'
import styles from './donation_list_ww_banner.module.css'
import { I18nHtml } from 'shared'
import { WwDonationListBannerProps } from './types'

export const DonationListWwBanner = ({ wwBranchName }: WwDonationListBannerProps) => {
  if (!wwBranchName) return null

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={SparkassenLogo} alt="" />
      </div>
      <I18nHtml
        className={styles.text}
        data-testid="ww-banner-text"
        i18nKey="projects.show.ww_hint_html"
        options={{
          branch_name: wwBranchName,
        }}
      />
    </div>
  )
}
