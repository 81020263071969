import React from 'react'
import classNames from 'classnames'
import styles from './modal.module.css'
import wirWunderLogo from '@betterplace/assets/images/logos/wirwunder_logo.svg'
import { MatchingEvent } from './types'
import { TrustedHtml } from 'shared'

export const ModalContent = ({
  matchingEvent,
  onHide,
  donateButton,
}: {
  matchingEvent: MatchingEvent
  onHide: React.MouseEventHandler<HTMLButtonElement>
  donateButton?: React.ReactNode
}): JSX.Element => {
  const backgroundColor = matchingEvent.backgroundColor
  const customLogo = matchingEvent.logo

  return (
    <div className={styles.container}>
      <div data-testid="matching-event-modal-content" className={styles.logoContainer} style={{ backgroundColor }}>
        <img className={styles.logo} src={customLogo || wirWunderLogo} alt="" />
        {!customLogo && matchingEvent.branchLogo && (
          <img className={styles.logo} src={matchingEvent.branchLogo} alt="" />
        )}
      </div>
      <div>
        <TrustedHtml as="p">{matchingEvent.modalDuration}</TrustedHtml>
        <TrustedHtml as="p">{matchingEvent.modalBudget}</TrustedHtml>
        <TrustedHtml as="p">{matchingEvent.modalMaximumAmount}</TrustedHtml>
        <TrustedHtml as="p" className={styles.furtherInfo}>
          {matchingEvent.modalFurtherInfo}
        </TrustedHtml>
        {matchingEvent.modalExtraInfo && <TrustedHtml as="p">{matchingEvent.modalExtraInfo}</TrustedHtml>}
      </div>
      <div
        className={classNames(
          styles.buttonWrapper,
          donateButton ? styles.multipleButtonsWrapper : styles.singleButtonWrapper
        )}
      >
        <button
          type="button"
          className={classNames(donateButton ? 'simulated-link' : 'btn btn-primary btn-large')}
          onClick={onHide}
        >
          <span>{i18n.t('misc.close')}</span>
        </button>
        {donateButton && donateButton}
      </div>
    </div>
  )
}
