import React from 'react'
import styles from './public_face_info.module.css'
import { I18nHtml } from 'shared'
import { PublicFaceInfoProps } from './types'

export const PublicFaceInfo = ({
  publicFacePictureUrl,
  publicFaceDisplayName,
  carrierName,
  newMessageUrl,
}: PublicFaceInfoProps) => {
  return (
    <div className={styles.container}>
      <img src={publicFacePictureUrl} alt="" className={styles.picture} />
      <div className={styles.text}>
        <I18nHtml
          i18nKey="projects.show.public_face_info.extended_display_name_html"
          options={{
            display_name: publicFaceDisplayName,
            carrier_name: carrierName,
          }}
        />
        <div className={styles.link}>
          <a href={newMessageUrl}>{i18n.t('projects.show.public_face_info.write_message_link')}</a>
        </div>
      </div>
    </div>
  )
}
