import React, { useCallback, useState } from 'react'
import { SubscribeCtaProps } from './types'
import { SubscribeModal } from 'ppp'

export const SubscribeCta = ({ projectTitle, url }: SubscribeCtaProps): JSX.Element => {
  const [show, setShow] = useState(false)

  const handleClick = useCallback(() => {
    setShow(true)
  }, [])

  const onHide = useCallback(() => {
    setShow(false)
  }, [])

  return (
    <>
      <p>{i18n.t('projects.show.engagement.subscribe_introduction')}</p>
      <button onClick={handleClick} className="simulated-link">
        {i18n.t('misc.learn_more_about_newsletter')}
      </button>

      <SubscribeModal show={show} projectTitle={projectTitle} url={url} onHide={onHide} />
    </>
  )
}
