import React, { useMemo } from 'react'
import classNames from 'classnames'
import styles from './section.module.css'
import { SectionProps } from './types'

export const Section = ({ children, id, spacing, background, fullWidth, backgroundMobileOnly }: SectionProps) => {
  const classes = useMemo(
    () =>
      classNames(styles.section, {
        container: !fullWidth,
        [styles.largeSpacingMobile]: spacing === 'large',
        [styles.hasBackground]: !!background,
        [styles.gentleBackground]: background === 'gentle',
        [styles.foggyBackground]: background === 'foggy',
        [styles.prominentBackground]: background === 'prominent',
        [styles.backgroundMobileOnly]: backgroundMobileOnly,
      }),
    [background, backgroundMobileOnly, fullWidth, spacing]
  )

  return (
    <section id={id} className={classes} tabIndex={id ? -1 : undefined}>
      {children}
    </section>
  )
}
