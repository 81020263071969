/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import classNames from 'classnames'
import styles from './need_list.module.css'
import { A11y, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { CompletedNeeds } from 'ppp/completed_needs'
import { LogoSpinner, SwiperNavigation, themeBreakpoint, themeColor, useJsonApi } from 'shared'
import { Need } from 'ppp/need/need'
import { NeedListProps, NeedsApiResult } from './types'
import { ProjectResultApiResponse } from 'shared/hooks/use_project/types'
import { Swiper, SwiperSlide } from 'swiper/react'

export const NeedList = ({ apiUrl, completedApiUrl, projectApiUrl }: NeedListProps): JSX.Element | null => {
  const { data: needs, loading: loadingNeeds } = useJsonApi<NeedsApiResult>(apiUrl)
  const { data: project, loading: loadingProject } = useJsonApi<ProjectResultApiResponse>(projectApiUrl)

  if (loadingNeeds || loadingProject) return <LogoSpinner smallSpinner color={themeColor('green-500')} />
  if (!needs || !project) return null

  const count = needs.total_entries
  if (!count) return null

  const breakpoint = themeBreakpoint()

  return (
    <div className={styles.container} data-testid="ppp-needs-list">
      <div className={styles.header}>
        <h2 className={styles.headline}>
          <span>{`${i18n.t('projects.show.needs.headline')} (${count})`}</span>
        </h2>
        {count > 3 && (
          <div className={styles.pagination}>
            <SwiperNavigation prefix="need-list" />
          </div>
        )}
      </div>

      {count === 1 ? (
        <div className={styles.singleNeedContainer}>
          <Need project={project} need={needs.data[0]!} />
        </div>
      ) : (
        <div className={classNames('generic-teaser-swiper', styles.swiperContainer)}>
          <Swiper
            modules={[Navigation, A11y, Pagination, Mousewheel]}
            spaceBetween={16}
            slidesPerView={1}
            pagination={{
              clickable: count > 1,
              dynamicBullets: count > 5 ? true : false,
            }}
            navigation={{
              nextEl: '#need-list-next',
              prevEl: '#need-list-prev',
              disabledClass: 'disabled',
            }}
            centeredSlides={count === 1}
            roundLengths
            watchSlidesProgress={true}
            mousewheel={{ forceToAxis: true }}
            breakpoints={{
              [breakpoint]: {
                slidesPerView: 3,
              },
            }}
            wrapperTag="ul"
            a11y={{
              slideRole: 'listitem',
            }}
          >
            {needs.data.map((need) => (
              <SwiperSlide key={need.id} tag="li" className={styles.slide}>
                <Need project={project} need={need} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {completedApiUrl && (
        <div className={styles.completedNeedsButton}>
          <CompletedNeeds apiUrl={completedApiUrl} />
        </div>
      )}
    </div>
  )
}
