import React from 'react'
import styles from './engagement.module.css'
import { EngagementProps, TeaserProps } from './types'

import heartFundraisingEventIcon from './icon-heart-fundraising.svg'
import newsletterIcon from './icon-newsletter.svg'

export const Engagement = ({
  allowFundraisingEvents,
  subscribeCta,
  createFundraisingEventCta,
}: EngagementProps): JSX.Element => {
  return (
    <div>
      <h2 className={styles.headline}>{i18n.t('projects.show.engagement.headline')}</h2>
      <div className={styles.teasers} data-testid="engagement-teasers">
        <Teaser iconUrl={newsletterIcon}>{subscribeCta}</Teaser>
        {allowFundraisingEvents && <Teaser iconUrl={heartFundraisingEventIcon}>{createFundraisingEventCta}</Teaser>}
      </div>
    </div>
  )
}

const Teaser = ({ iconUrl, children }: TeaserProps) => {
  return (
    <div className={styles.teaser}>
      <img src={iconUrl} alt="" />
      <div className={styles.teaserContent}>{children}</div>
    </div>
  )
}
