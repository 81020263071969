import React from 'react'
import classNames from 'classnames'
import styles from './progress.module.css'
import { ProgressProps } from './types'
import { formatCents } from '@betterplace/utils'

export const Progress = ({
  progressPercentage,
  donatedAmountInCents,
  targetAmountInCents,
  donationsCount,
  link,
}: ProgressProps): JSX.Element => {
  return (
    <div data-testid="ppp-progress">
      <div className={styles.header}>
        <div className={styles.totalAmount}>
          <strong className={classNames(styles.number, styles.largeNumber)}>
            {formatCents(i18n.locale, donatedAmountInCents)}
          </strong>
          <span>
            {targetAmountInCents - donatedAmountInCents > 0 ? (
              <>
                {i18n.t('projects.show.stats.of_GOAL_collected', {
                  goal: formatCents(i18n.locale, targetAmountInCents),
                })}
              </>
            ) : (
              <>{i18n.t('projects.show.stats.collected')}</>
            )}
          </span>
        </div>
        <div className={styles.link}>{link}</div>
      </div>
      <div className={styles.progressBar}>
        <ProgressBar percentage={progressPercentage} />
      </div>
      <div className="generic-big-numbers">
        <div className={styles.progressText}>
          <div>
            <span className={styles.number}>{progressPercentage} %</span>
            <span className={styles.label}>{i18n.t('projects.show.stats.percentage_label')}</span>
          </div>
          <div>
            <span className={styles.number}>{donationsCount}</span>
            <span className={styles.label}>
              {i18n.t('projects.show.stats.donations_count_label', { count: donationsCount })}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProgressBar = ({ percentage }: { percentage: number }): JSX.Element => {
  return (
    <div className="progress generic-progress-bar">
      <div className="bar" style={{ width: `${percentage}%` }}></div>
    </div>
  )
}
