import React from 'react'
import itzUrl from '@betterplace/assets/images/logos/itz-colored.svg'
import logoUrl from '@betterplace/assets/images/logos/orga-logo.svg'
import styles from './about.module.css'

export const About = () => {
  return (
    <div className={styles.container}>
      <div className="container">
        <div className="row">
          <div className="col-md-20 offset-md-2">
            <h2 className={styles.headline}>
              <span>{i18n.t('projects.show.about_betterplace.about')}</span>
              <img
                src={logoUrl}
                width={220}
                className={styles.betterplaceLogo}
                alt={i18n.t('layouts.footer.logo_alt_text')}
              />
            </h2>
          </div>
          <div className="col-md-20 offset-md-2">
            <p className={styles.text}>{i18n.t('projects.show.about_betterplace.about_text')}</p>
          </div>
          <div className="col-md-20 offset-md-2">
            <ul className={styles.facts}>
              {[1, 2, 3].map((i) => {
                return (
                  <li className={styles.fact} key={i}>
                    <strong className={styles.factNumber}>
                      {i18n.t(`projects.show.about_betterplace.fact_${i}_number`)}
                    </strong>
                    <div>{i18n.t(`projects.show.about_betterplace.fact_${i}_text`)}</div>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="col-md-20 offset-md-2 desktop:hidden">
            <a href="/c/ueber-uns/initiative-transparente-zivilgesellschaft">
              <img src={itzUrl} width="240" height={65} alt={i18n.t('layouts.footer.itz_logo_alt_text')} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
