import React, { useMemo } from 'react'
import classNames from 'classnames'
import styles from './need.module.css'
import { GtmHelper, TrustedHtml, findApiV4Link } from 'shared'
import { formatCents } from '@betterplace/utils'

import { NeedProps } from './types'
import { useCallback } from 'react'

const useDonateUrl = (project: NeedProps['project'], need: NeedProps['need']): string | undefined => {
  return useMemo(
    () => (!project.donations_prohibited ? findApiV4Link({ ofObject: need, withRel: 'new_donation' }) : undefined),
    [project.donations_prohibited, need]
  )
}

export const Need = ({ project, need }: NeedProps): JSX.Element => {
  const donateUrl = useDonateUrl(project, need)

  const setTracking: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      if (!donateUrl) {
        event.preventDefault()
        event.stopPropagation()
        return
      }
      project && GtmHelper.pushAddToBasket(project)
    },
    [project, donateUrl]
  )

  const classes = useMemo(
    () =>
      classNames(styles.container, {
        [styles.disabled]: project && project.donations_prohibited,
      }),
    [project]
  )

  return (
    <div className={classes}>
      <div className={styles.title}>{need.title}</div>
      <div className={styles.amount}>
        {i18n.t('projects.show.needs.still_missing_label', {
          count: need.open_amount_in_cents,
          amount: formatCents(i18n.locale, need.open_amount_in_cents, { precision: 0 }),
          total: formatCents(i18n.locale, need.requested_amount_in_cents, { precision: 0 }),
        })}
      </div>
      <TrustedHtml className={styles.description} as="p">
        {need.description}
      </TrustedHtml>
      {project && !project.donations_prohibited && (
        <a href={donateUrl} onClick={setTracking} className="btn btn-primary btn-large" data-testid="ppp-donate-button">
          {i18n.t('projects.show.needs.donate_button_label')}
        </a>
      )}
    </div>
  )
}
