import React, { useCallback, useMemo, useRef, useState } from 'react'
import styles from './description.module.css'
import useResizeObserver from 'use-resize-observer'
import { I18nHtml, TrustedHtml } from 'shared'

export const Description = ({ description }: { description: string }) => {
  const [isOverflowing, setIsOverflowing] = useState<boolean | null>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const ref = useRef<null | HTMLDivElement>(null)

  const lineHeight = 22.8833
  const maxHeight = lineHeight * 14
  let animationFrame: number | undefined
  const isResizeObserverSupported = useMemo(() => 'ResizeObserver' in window, [])

  useResizeObserver({
    ref: isResizeObserverSupported ? ref : null,
    onResize: ({ height: observedHeight }) => {
      animationFrame = window.requestAnimationFrame(() => {
        if (!observedHeight) return
        setIsOverflowing(observedHeight > maxHeight)
      })
    },
  })

  const isTruncated = useMemo(() => {
    return isOverflowing && !isExpanded && isResizeObserverSupported
  }, [isExpanded, isOverflowing, isResizeObserverSupported])

  const expandDescription = useCallback(() => {
    setIsExpanded(true)

    if (animationFrame) {
      window.cancelAnimationFrame(animationFrame)
    }
  }, [animationFrame])

  return (
    <>
      <div className={styles.container} data-testid="wrapper" style={{ maxHeight: isTruncated ? maxHeight : 'none' }}>
        <div ref={ref} data-testid="description">
          {description ? (
            <TrustedHtml>{description}</TrustedHtml>
          ) : (
            <I18nHtml i18nKey="projects.show.description.placeholder_html" />
          )}
        </div>
        {isTruncated && <Fader lineHeight={lineHeight} />}
      </div>
      {isTruncated && (
        <button className="simulated-link" onClick={expandDescription}>
          {i18n.t('misc.read_more')}
        </button>
      )}
    </>
  )
}

const Fader = ({ lineHeight }: { lineHeight: number }) => (
  <div
    className={styles.fader}
    style={{
      height: `${lineHeight}px`,
    }}
  />
)
