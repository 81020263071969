import Layouts from 'shared/components/layouts'
import React, { useCallback } from 'react'
import styles from './header.module.css'
import { HeaderProps } from './types'
import { PopoverText } from 'shared'
import { Title } from 'ppp/title'

export const Header = ({ projectTitle, projectStatus, profilePicture, gallery, videoEmbedUrl }: HeaderProps) => {
  const onVideoLinkClicked: React.MouseEventHandler<HTMLAnchorElement> = useCallback((event) => {
    event.preventDefault()
    const videoPlayer = document.querySelector('#video-player')
    if (videoPlayer instanceof HTMLElement) {
      videoPlayer.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => videoPlayer.focus(), 500)
    }
  }, [])

  return (
    <header>
      <Layouts.Stack space="var(--space-sm)">
        <div className={styles.galleryContainer}>
          {profilePicture ? (
            gallery
          ) : (
            <div className={styles.galleryPlaceholderContainer}>
              <span className={styles.galleryPlaceholderContent}>{i18n.t('projects.show.gallery.placeholder')}</span>
            </div>
          )}
        </div>

        {videoEmbedUrl && (
          <a href="#video-player" className={styles.videoLink} onClick={onVideoLinkClicked}>
            {i18n.t('projects.show.watch_video_link')}
          </a>
        )}

        <div className={`${styles.title} container`}>
          <Title projectTitle={projectTitle} />
          <ul className={styles.badges}>
            {projectStatus == 'activated' && (
              <li className={styles.badgeCertified}>
                <PopoverText
                  trigger={
                    <button type="button" aria-describedby="badge-certified-tooltip">
                      {i18n.t('projects.show.badges.certified_non_profit.title')}
                    </button>
                  }
                  placement="bottom"
                  popoverId="badge-certified-tooltip"
                >
                  {i18n.t('projects.show.badges.certified_non_profit.tooltip')}
                </PopoverText>
              </li>
            )}
          </ul>
        </div>
      </Layouts.Stack>
    </header>
  )
}
