import React, { useCallback, useState } from 'react'
import styles from './donations_list.module.css'
import { Controls, I18nHtml, useJsonApi } from 'shared'
import { Donation } from 'ppp/donation'
import { DonationList, DonationsListProps } from './types'
import { DonationListWwBanner } from 'ppp/donation_list_ww_banner/donation_list_ww_banner'

export const DonationsList = ({ apiUrl, donationsCount, wwBranchName }: DonationsListProps) => {
  const [activePage, setActivePage] = useState(1)
  const perPage = 3

  const { data: donationList } = useJsonApi<DonationList>(
    apiUrl + `?per_page=${perPage}&page=${activePage}&order=confirmed_at:desc`
  )

  const previousPage = useCallback(() => {
    setActivePage(activePage - 1)
  }, [activePage])

  const nextPage = useCallback(() => {
    setActivePage(activePage + 1)
  }, [activePage])

  return (
    <div className={styles.container} data-testid="donation-list">
      <div className={styles.header}>
        <I18nHtml
          as="h2"
          className={styles.headline}
          i18nKey="projects.show.donations_list.headline_html"
          options={{ count: donationsCount }}
        />

        {donationList && donationList.total_entries > 3 && (
          <Controls
            total={Math.ceil(donationList.total_entries / donationList.per_page)}
            current={activePage}
            loadNextPage={nextPage}
            loadPreviousPage={previousPage}
          />
        )}
      </div>
      {donationList && (
        <ul className={styles.list}>
          {activePage === 1 && wwBranchName && (
            <li>
              <DonationListWwBanner wwBranchName={wwBranchName} />
            </li>
          )}
          {donationList.data.map((donation) => {
            return <Donation key={donation.id} donation={donation} as="li" />
          })}
        </ul>
      )}
    </div>
  )
}
