import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import styles from './completed_needs.module.css'
import { CompletedNeedsProps, Need, NeedsApiResult, ShowModalLinkProps, ShowMoreNoticeProps } from './types'
import { Modal, PopoverIcon, Table, useJsonApi } from 'shared'
import { formatCents } from '@betterplace/utils'

/**
 * @func CompletedNeeds
 * @param {CompletedNeedsProps} props
 * @returns {JSX.Element}
 */
export const CompletedNeeds = ({ apiUrl }: CompletedNeedsProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = () => setShowModal(false)
  const { data } = useJsonApi<NeedsApiResult>(apiUrl)
  const needs = data?.data || []
  const count = (data && data.total_entries) || 0

  return (
    <>
      <div data-testid="completed-needs">
        <ShowModalLink count={count} setShowModal={setShowModal} />
      </div>
      <Modal
        id="completed-needs-modal"
        onHide={hideModal}
        show={showModal}
        header={i18n.t('projects.show.completed_needs.headline')}
        footer={
          <button type="button" className="btn btn-default" onClick={hideModal}>
            {i18n.t('projects.show.completed_needs.close_link_text')}
          </button>
        }
      >
        <p>
          <strong>{i18n.t('projects.show.completed_needs.thank_you_message')}</strong>
        </p>
        <Table striped>
          {needs.map((need, i) => (
            <NeedRow {...need} key={i} />
          ))}
        </Table>
        <ShowMoreNotice count={count} />
      </Modal>
    </>
  )
}

const ShowModalLink = ({ count, setShowModal }: ShowModalLinkProps): JSX.Element | null => {
  const onClick = useCallback(() => setShowModal(true), [setShowModal])
  if (!count) return null
  return (
    <button className={classNames(styles.modalLink, 'simulated-link')} onClick={onClick}>
      {i18n.t('projects.show.completed_needs.link_text')}
    </button>
  )
}

const ShowMoreNotice = ({ count }: ShowMoreNoticeProps): JSX.Element | null => {
  const overflow = count - 20
  if (overflow <= 0) return null
  return (
    <p>
      {i18n.t('projects.show.completed_needs.more_completed_needs_notice', {
        more_count: overflow,
      })}
    </p>
  )
}

const NeedRow = (need: Need): JSX.Element => (
  <tr key={need.id} className={styles.needRow}>
    <td className={styles.needData}>
      {need.title}{' '}
      <PopoverIcon popoverId="tooltip-for-need" icon="info">
        <p>{need.description}</p>
        <p>
          <small>
            {i18n.t('projects.show.completed_needs.created_date_line', {
              date: i18n.l('date.formats.short_date_with_week_day', need.created_at),
            })}
          </small>
        </p>
      </PopoverIcon>
    </td>
    <td className={styles.needData}>
      {formatCents(i18n.locale, need.requested_amount_in_cents)}&nbsp;
      {i18n.t('projects.show.completed_needs.collected_label')}
    </td>
  </tr>
)
