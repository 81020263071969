import React from 'react'
import styles from './project_info.module.css'
import { ProjectInfoProps } from './types'

export const ProjectInfo = ({ media, content, sidebar }: ProjectInfoProps) => {
  return (
    <>
      <h2 className={styles.headline}>{i18n.t('projects.show.description.headline')}</h2>
      <div className={styles.gridContainer}>
        {media && <div className={styles.gridItem}>{media}</div>}
        <div className={styles.gridItemSidebar}>{sidebar}</div>
        <div className={styles.gridItem}>{content}</div>
      </div>
    </>
  )
}
